<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="this.$t('MENU.AUTO_WHITELIST')">
          <!-- <template v-slot:toolbar>
            <b-button variant="primary" class="mr-4" @click="searchData()">
              {{ $t("COMMON.SEARCH") }}
            </b-button>
            <b-button
              variant="danger"
              v-b-modal.confirm-1
              :disabled="awhitelistTable.selected.length == 0"
            >
              {{ $t("COMMON.DELETE") }}
            </b-button>
          </template> -->
          <template v-slot:body>
            <b-row class="table-filter">
              <b-col sm="6" class="my-3">
                <b-row>
                  <b-col sm="3" class="setting-form">
                    <label for="select-domain">{{
                      $t("COMMON.SEL_DOMAIN")
                    }}</label>
                  </b-col>
                  <b-col sm="9">
                    <model-list-select :list="selectDomain.allDomains"
                      v-model="selectDomain.selectedDomain"
                      option-value="domain"
                      option-text="domain"
                      :placeholder="$t('COMMON.SEL_DOMAIN')"
                      id="select-domain">
                    </model-list-select>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="6" class="my-3">
                <b-row>
                  <b-col sm="3" class="setting-form">
                    <label for="pick-date">{{
                      $t("FILTERING.AUTO_WHITELIST.PICK_DATE")
                    }}</label>
                  </b-col>
                  <b-col sm="9">
                    <b-form-select
                      id="pick-date"
                      v-model="searchForm.pickdate.selected"
                      :options="searchForm.pickdate.data"
                      value-field="value"
                      text-field="text"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="6" class="my-3">
                <b-row>
                  <b-col sm="3" class="setting-form">
                    <label for="addressLike">{{
                      $t("FILTERING.AUTO_WHITELIST.ADDRESS_LIKE")
                    }}</label>
                  </b-col>
                  <b-col sm="9">
                    <b-form-input
                      id="addressLike"
                      v-model="searchForm.address"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col class="d-flex justify-content-end">
                <b-button variant="primary" class="mr-4" @click="searchData()">
                  {{ $t("COMMON.SEARCH") }}
                </b-button>
                <b-button
                  variant="danger"
                  v-b-modal.confirm-1
                  v-if="canRemove"
                  :disabled="awhitelistTable.selected.length == 0"
                >
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </b-col>
            </b-row>
            <v-data-table
              v-model="awhitelistTable.selected"
              :headers="headers"
              :items="awhitelistTable.awhitelist"
              :single-select="awhitelistTable.singleSelect"
              :items-per-page="awhitelistTable.itemPerPage"
              :server-items-length="awhitelistTable.total"
              :options.sync="awhitelistTable.pagination"
              @update:items-per-page="changePerPage"
              @update:page="getAwhitelistByPagination"
              show-select
              item-key="id"
              class="elevation-1"
              :footer-props="{
                showFirstLastPage: true,
              }"
            >
              <template v-slot:item.action="{ item }">
                <v-icon
                  medium
                  color="dark"
                  @click="saveDeltem(item)"
                  v-b-modal.confirm-2
                  v-b-tooltip.hover
                  v-if="canRemove"
                  :title="$t('COMMON.DELETE')"
                >
                  delete
                </v-icon>
              </template>
            </v-data-table>
            <!-- ----------delete confirm modal------------- -->
            <b-modal
              id="confirm-1"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteItems">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>

            <!-- ----------delete confirm modal------------- -->
            <b-modal
              id="confirm-2"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteOneItem">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------delete confirm modal end------------- -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import moment from "moment";

export default {
  name: "awhitelist",
  components: {
    KTPortlet,
  },
  data: () => ({
    awhitelistTable: {
      singleSelect: false,
      awhitelist: [],
      selected: [],
      itemPerPage: 5,
      total: 0,
      pagination: {},
      position: 0,
    },
    selectDomain: {
      selectedDomain: {},
      allDomains: [],
    },
    searchForm: {
      pickdate: {
        selected: "",
        data: [],
      },
      address: "",
    },
    delItem: "",
  }),
  computed: {
    headers: function () {
      return [
        {
          text: this.$t("COMMON.USERNAME"),
          value: "username",
          sortable: false,
        },
        { text: this.$t("COMMON.ADDRESS"), value: "address", sortable: false },
        {
          text: this.$t("COMMON.CHECK_SPAM"),
          value: "check_spam",
          sortable: false,
        },
        {
          text: this.$t("COMMON.CHECK_VIRUS"),
          value: "check_virus",
          sortable: false,
        },
        { text: this.$t("COMMON.ACTION"), value: "action", sortable: false },
      ];
    },
    canRemove: function () {
      return this.hasPermission("filtering", 7);
    },
  },
  created() {
    postRequest({
      action: "getAllDomains",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ domain: res.returndata }];
        }
        this.selectDomain.allDomains = res.returndata;
        this.selectDomain.selectedDomain = res.returndata[0];
      }
    });
    postRequest({
      action: "getAdminDateFormat",
      token: localStorage.id_token,
    }).then((res) => {
      if (res.returncode) {
        const datetype = res.returndata.dateformat.toUpperCase();
        const todayDate = res.returndata.today;
        const dateArray = [
          {
            value: 0,
            text: "All days",
          },
        ];
        for (let i = 30; i >= 0; i--) {
          let newDate = todayDate - 3600 * 24 * i;
          const eachDay = moment(newDate * 1000).format(datetype);
          dateArray.push({
            value: newDate,
            text: eachDay,
          });
        }

        this.searchForm.pickdate.selected = todayDate;
        this.searchForm.pickdate.data = dateArray;
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title:
          this.$t("MENU.FILTERING") + " -> " + this.$t("MENU.AUTO_WHITELIST"),
      },
    ]);
  },
  methods: {
    /*  -----modal close------- */
    confirmModalClose(modalRef) {
      this.$refs[modalRef].hide();
    },

    /* ---------read item---------- */
    getAwhitelistReqest(offset, limit) {
      this.awhitelistTable.awhitelist = [];
      this.awhitelistTable.selected = [];
      postRequest({
        action: "getDomainAutoWhitelist",
        token: localStorage.id_token,
        offset: offset,
        limit: limit,
        pickdate: this.searchForm.pickdate.selected,
        address: this.searchForm.address,
        domain: this.selectDomain.selectedDomain.domain,
      }).then((res) => {
        if (res.returncode) {
          for (let i = 0; i < res.returndata.length; i++) {
            if (res.returndata[i].check_spam == 0) {
              res.returndata[i].check_spam = this.$t("COMMON.NO");
            } else {
              res.returndata[i].check_spam = this.$t("COMMON.YES");
            }
            if (res.returndata[i].check_virus == 0) {
              res.returndata[i].check_virus = this.$t("COMMON.NO");
            } else {
              res.returndata[i].check_virus = this.$t("COMMON.YES");
            }
            if (res.returndata[i].username == null) {
              res.returndata[i].username = "null";
            }
          }
          this.awhitelistTable.awhitelist = res.returndata;
          this.awhitelistTable.total = res.meta.all;
          this.awhitelistTable.position = res.meta.position;
        } else {
          this.awhitelistTable.awhitelist = [];
          this.awhitelistTable.total = 0;
        }
      });
    },

    searchData() {
      this.getAwhitelistReqest(0, this.awhitelistTable.itemPerPage);
    },
    changePerPage(arg) {
      this.awhitelistTable.itemPerPage = arg;
      this.getAwhitelistReqest(0, arg);
    },
    getAwhitelistByPagination() {
      const pageOffset =
        (this.awhitelistTable.pagination.page - 1) *
        this.awhitelistTable.itemPerPage;
      this.getAwhitelistReqest(pageOffset, this.awhitelistTable.itemPerPage);
    },

    /* -------delete item----- */
    saveDeltem(item) {
      this.delItem = item;
    },
    deleteOneItem() {
      const item = this.delItem;
      postRequest({
        action: "removeWBList",
        token: localStorage.id_token,
        lid: item.id,
      }).then((res) => {
        this.confirmModalClose("delConfirm-modal");
        this.makeToastVariant("success", res.returndata);
        for (let i = 0; i < this.awhitelistTable.awhitelist.length; i++) {
          if (this.awhitelistTable.awhitelist[i].id == item.id) {
            this.awhitelistTable.awhitelist.splice(i, 1);
            break;
          }
        }
      });
    },
    deleteItems() {
      if (this.awhitelistTable.selected.length > 0) {
        this.awhitelistTable.selected.forEach((el) => {
          postRequest({
            action: "removeWBList",
            token: localStorage.id_token,
            lid: el.id,
          }).then((res) => {
            this.confirmModalClose("delConfirm-modal");
            this.makeToastVariant("success", res.returndata);
            for (let i = 0; i < this.awhitelistTable.awhitelist.length; i++) {
              if (this.awhitelistTable.awhitelist[i].id == el.id) {
                this.awhitelistTable.awhitelist.splice(i, 1);
              }
            }
          });
        });
      }
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
  },
};
</script>
